/*
  Endeca control class for the typeahead endeca instance.
*/
var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.typeahead = site.endeca.instances.typeahead || {};

(function($) {

site.endeca.instances.typeahead.control = site.endeca.generic.Class.create(site.endeca.control, {
    initialize: function (args) {
        this.timer = 0;
        this._super(args);

        if (this.nodes.wrapper.length && this.nodes.inputElements.length) {
            var that = this;
            this.nodes.inputElements.bind('keyup', that, that.onKeyUp);
            this._watchToClose();
        }

        //$(document).trigger('endeca.typeahead.init');
    },

    onKeyUp: function (event) {
        var $this = jQuery(this);
        var that = event.data;
        clearTimeout(that.timer);
        that.timer = setTimeout(function () {
            that.currentInputElement = $this;
            var searchTerm = jQuery.trim( $this.val() );
            if (searchTerm != that.searchTerm && searchTerm.length >= that.configuration.minSearchLength) {
                searchTerm = searchTerm + "*";
                that.searchTerm = searchTerm;

                /* Adobe Start set or update cookie on search term here */
                if (typeof s == 'object') {
                   if (typeof s.c_w == 'function') {
                       s.c_w('TYPEAHEAD', that.searchTerm);
                   }
                }
                /*  Adobe end */

                that.search({
                    searchTerm: searchTerm
                });
            }
        else if (searchTerm.length === 0) {
          that.nodes.wrapper.addClass('hidden');
          that.nodes.popularBlock.removeClass('hidden');
          that.nodes.clearElement?.addClass('hidden');
        }
        }, 175);
    },

    _watchToClose: function () {
        var that = this;
        jQuery(document).bind('click', that, function (event) {
            var tgt = event.target;
            var that = event.data;

            if (!jQuery(tgt).parents(that.nodes.wrapper.selector).length &&
                tgt != that.currentInputElement &&
                tgt != that.nodes.wrapper[0]) {
                that.nodes.wrapper.addClass('hidden');
                that.nodes.popularBlock.removeClass('hidden');
                if (Drupal?.settings?.common?.search_modernized_design) {
                  if (jQuery(tgt).hasClass('js-esearch-nav__form-clear')) {
                    that.currentInputElement.val('');
                    that.currentInputElement.focus();
                    that.nodes.clearElement.addClass('hidden');
                  }
                }
                return;
            }

          $(document).trigger('endeca.typeahead.close');
        });
    },

    searchCompleted: function () {
      var $resultElements;
      var searchTerm;

        if (!this._super()) {
            return;
        }
        this.hasResults = false;
        var $searchType = $('.js-search-type');
        var $searchAnalyticTag = $searchType.length > 0 ? '&search_type=' +  $searchType.val() : '';

        if (this.queries?.product?.jsonResult?.AggrRecords?.length) {
            var termResultDataRef = {};
            var termResultData = [];

            for ( var i = 0; i < this.queries.product.jsonResult.AggrRecords.length; i++ ) {
                if ( this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] ) {
                    var matches;

                    if ( typeof this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] == "string" ) {
                        matches = [ this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] ];
                    } else {
                        matches = this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"];
                    }

                  for ( var j = 0; j < matches.length; j++ ) {
                        if ( matches[j] && matches[j].match(/Product Category: (.*)/)) {
                            if ( ! RegExp.$1.match(/^CAT/) ) {

                                if ( termResultDataRef[ RegExp.$1 ] ) {
                                    termResultDataRef[ RegExp.$1 ]++;
                                } else {
                                    termResultDataRef[ RegExp.$1 ] = 1;
                                    termResultData.push({
                                        'term': RegExp.$1,
                                        'url': this.configuration.fullSearchBaseUrl + encodeURIComponent(RegExp.$1) + $searchAnalyticTag
                                    });
                                }
                            }
                        }
                    }
                }
            }

            // ensuring term suggestion starts with what is already entered into search bar so auto-complete will look correct
            if (termResultData[0]) {
              var term = this.searchTerm.replace('*', '');
              var regexTermStart = new RegExp('^' + term, 'i');
              var suggestion = termResultData[0].term;
              if (! suggestion.match(regexTermStart)) {
               termResultData[0].term = "";
               termResultData[0].url = "";
              }
            }

            if ( this.results.terms.configuration.limit && termResultData.length > this.results.terms.configuration.limit ) {
                termResultData.splice( this.results.terms.configuration.limit );
            }

            termResultData.sort( function( a, b ) {
                if ( termResultDataRef[a] > termResultDataRef[b] ) { return -1; }
                   else if ( termResultDataRef[a] < termResultDataRef[b] ) { return 1; }
                return 0;
            });


            this.results.terms.resultData = termResultData;
            this.results.terms.displayResults();
            this.results.terms.displayResultNodes();
            this.results.terms.show();
            this.hasResults = true;
        } else {
            this.results.terms.hide();
        }

        this.results.products.resultData = this.catalogs.product.resultList;

        if (this.results.products.resultData.length) {
            // this is to trim the list of images, in cases where a product has more than one, to just one image
            // (to deal with the front-end's brittle handling of lists, not being able to do PROP.0 to get the
            // first property)
            for (var i = 0; i < this.results.products.resultData.length; i += 1) {
                for (var prop in this.results.products.resultData[i]) {
                    if (prop.indexOf("IMAGE_") === 0 && typeof this.results.products.resultData[i][prop] !== "string") {
                        this.results.products.resultData[i][prop] = this.results.products.resultData[i][prop][0];
                    }
                }
            }

            this.results.products.displayResults();
            this.results.products.show();
            this.hasResults = true;
            if ( Drupal?.settings?.common?.search_modernized_design ) {
                this.displayPowerReviewRating(this.results.products.resultData);
            }
        } else {
            this.results.products.hide();
        }

        if ( this.hasResults ) {
            /* Add first search result */
            searchTerm = this.searchTerm.replace('*', '');
            var showResults = rb.endeca["show_results"];
            var youSearched = rb.endeca.search_results_yousearched;
            var prefix = rb.endeca.search_results_totals_prefix;
            var divider = rb.endeca.search_results_totals_divider;
            var suffix = rb.endeca.search_results_totals_suffix;
            showResults = showResults.replace('::term::', searchTerm);
            var search_result_custom_text = Drupal.settings.common.search_result_custom_text;
        var search_text_custom_pos = Drupal.settings.common.search_text_custom_pos;
            if ( search_result_custom_text ) {
              this.results.seeResults.resultData = [{
                'term': '<span class="search-count"><span class="search-term">"' + searchTerm + '"</span> ' + youSearched +' '+ this.meta.searchInfo.totalRecords +' '+ prefix +' '+ this.results.products.resultNodes.length + ' '+ suffix + '</span>',
                'url': this.configuration.fullSearchBaseUrl + searchTerm + $searchAnalyticTag
              }];
        } else if (search_text_custom_pos) {
          this.results.seeResults.resultData = [{
            'term': '<span class="search-count"><span class="search-term">"' + searchTerm + '"</span> ' + youSearched + ' ' + this.meta.searchInfo.totalRecords + ' ' + prefix + ' ' + this.results.products.resultNodes.length + ' ' + suffix + '</span> <span class="search-results">' + showResults + '</span>',
            'url': this.configuration.fullSearchBaseUrl + searchTerm + $searchAnalyticTag
          }];
            } else {
              this.results.seeResults.resultData = [{
                'term': '<span class="search-count">' +
                  youSearched +
                  ' <span class="search-term"> "' + searchTerm + '"</span> '  +
                  prefix + ' '  +
                  this.results.products.resultNodes.length + ' ' +
                  divider + ' ' +
                  this.meta.searchInfo.totalRecords + ' ' +
                  suffix +
                  '</span> <span class="search-results">' +
                  showResults +
                  '</span>',
                'url': this.configuration.fullSearchBaseUrl + searchTerm + $searchAnalyticTag
              }];
            }

        if (Drupal?.settings?.common?.search_modernized_design) {
          this.nodes.popularBlock.addClass('hidden');
        }
            this.results.seeResults.displayResults();
            this.results.seeResults.displayResultNodes();
            this.results.seeResults.show();
      } else if (Drupal?.settings?.common?.search_modernized_design) {
        searchTerm = this.searchTerm.replace('*', '');
        let noResults = rb.endeca['typeahead_no_results'];

        searchTerm = ' <span class="search-term"> "' + searchTerm + '"</span> ';
        noResults = noResults.replace('::term::', searchTerm);
        this.results.noResults.resultData = [
          {
            no_result: noResults
          }
        ];
        this.results.noResults.displayResults();
        this.results.noResults.displayResultNodes();
        this.results.noResults.show();
        this.results.seeResults.hide();
        this.nodes.popularBlock.removeClass('hidden');
      }

        this.nodes.wrapper.removeClass('hidden');
      if (Drupal?.settings?.common?.search_modernized_design) {
        this.nodes.clearElement.removeClass('hidden');
      } else {
        this.nodes.popularBlock.addClass('hidden');
      }

        var me = this.nodes;

      if (this.results.products.node) {
        $resultElements = this.results.products.node.find('.result');

        if ($resultElements && $resultElements.length > 0) {
          $resultElements.bind('click', function () {
            site.endeca.omniture.productClick();

            return true;
          });
        }
      }

        this.hideLoading();
        this.processOmniture();

//         $(document).trigger('endeca.typeahead.complete', [ this.hasResults ]);
//
//         this.displayResults();
    },
    displayPowerReviewRating: function (resultData) {
      var $targetElement = $('.js-product', this.results.products.node);

      $targetElement.trigger('products.init-start');
      $targetElement.trigger('product.init', [resultData]);
      $targetElement.trigger('products.init-finish');
    }
});

})(jQuery);
